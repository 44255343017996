<template>
    <div>
        <b-breadcrumb style="background-color: #ffffff">
            <b-breadcrumb-item style="color: #0000FF!important;" href="#/home">Home</b-breadcrumb-item>
            <b-breadcrumb-item active href="#">{{ this.$route.query.boxName }}</b-breadcrumb-item>
        </b-breadcrumb>
        <h1 class="text-left">{{ this.$route.query.boxName }}</h1>
        <b-row class="justify-content-center">
            <b-card-group class="justify-content-center" deck>
                <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
                    <b-card
                            style="max-width: 40rem; margin-left: auto; margin-right: auto"
                    >
                        <b-card-title class="ubuntu">Step 1</b-card-title>
                        <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
                            {{ $t('macMenu.step1') }}
                        </b-card-text>
                        <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
                            {{ $t('macMenu.ti9Step1') }}
                        </b-card-text>
                        <b-card-text v-else-if="this.$route.query.boxName === 'Q80'" class="sansSerif text-left">
                            {{ $t('macMenu.q80Step1') }}
                        </b-card-text>
                        <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                                    :src="require('../assets/English-Main-Menu-step-1.png')" alt="Step 1"
                                    top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                                    :src="require('../assets/Italian-Main-Menu-step-1.png')" alt="Step 1"
                                    top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat')"
                                    :src="require('../assets/menu1.png')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat')"
                                    :src="require('../assets/menu1-it.jpg')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q80'"
                                    :src="require('../assets/1Menu.png')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q80'"
                                    :src="require('../assets/q80_1.png')" alt="Step 1" top></b-card-img>
                    </b-card>
                </b-col>
                <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
                    <b-card
                            style="max-width: 40rem; margin-left: auto; margin-right: auto"
                    >
                        <b-card-title class="ubuntu">Step 2</b-card-title>
                        <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
                            {{ $t('macMenu.step2') }}
                        </b-card-text>
                        <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
                            {{ $t('macMenu.ti9Step2') }}
                        </b-card-text>
                        <b-card-text v-else-if="this.$route.query.boxName === 'Q80'" class="sansSerif text-left">
                            {{ $t('macMenu.q80Step2') }}
                        </b-card-text>
                        <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                                    :src="require('../assets/English-Installation-step-2.png')" alt="Step 2"
                                    top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                                    :src="require('../assets/Italian-Installation-step2.png')" alt="Step 2"
                                    top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat')"
                                    :src="require('../assets/menu2.png')" alt="Step 2" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat')"
                                    :src="require('../assets/menu2-it.jpg')" alt="Step 2" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q80'"
                                    :src="require('../assets/2Installation.png')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q80'"
                                    :src="require('../assets/q80_2.png')" alt="Step 1" top></b-card-img>
                    </b-card>
                </b-col>
                <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
                    <b-card
                            style="max-width: 40rem; margin-left: auto; margin-right: auto"
                    >
                        <b-card-title class="ubuntu">Step 3</b-card-title>
                        <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
                            {{ $t('macMenu.step3') }}
                        </b-card-text>
                        <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
                            {{ $t('macMenu.ti9Step3') }}
                        </b-card-text>
                        <b-card-text v-else-if="this.$route.query.boxName === 'Q80'" class="sansSerif text-left">
                            {{ $t('macMenu.q80Step3') }}
                        </b-card-text>
                        <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                                    :src="require('../assets/English-Software-Information-step-3.png')" alt="Step 3"
                                    top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                                    :src="require('../assets/Italian-Software-Information-step-3.png')" alt="Step 3"
                                    top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat')"
                                    :src="require('../assets/menu3.png')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Maximo' || this.$route.query.boxName === 'Microsat')"
                                    :src="require('../assets/menu3-it.jpg')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q80'"
                                    :src="require('../assets/3SystemInformation.png')" alt="Step 1" top></b-card-img>
                        <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q80'"
                                    :src="require('../assets/q80_3.png')" alt="Step 1" top></b-card-img>
                    </b-card>
                </b-col>
                <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
                </b-col>
            </b-card-group>
        </b-row>
        <div class="justify-content-center">
            <b-row class="my-5">
                <b-col sm="2">
                    <label :for="macAddress">MAC Address:</label>
                </b-col>
                <b-col sm="4">
                    <b-form-input :placeholder="this.$i18n.messages[this.$i18n.locale].macMenu.enterMacWithSymbols"
                                  :state="macAddressState" v-model="macAddress" id="macAddress"
                                  type="text"></b-form-input>
                    <b-form-invalid-feedback id="password-feedback">
                        {{ $t('macMenu.invalidMac') }}
                    </b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row class="my-5">
                <b-col sm="2">
                    <label :for="macAddress">Confirm MAC Address:</label>
                </b-col>
                <b-col sm="4">
                    <b-form-input :placeholder="this.$i18n.messages[this.$i18n.locale].macMenu.enterMacWithSymbols"
                                  :state="macAddressConfirmState" v-model="macAddressConfirm" id="macAddress"
                                  type="text"></b-form-input>
                    <b-form-invalid-feedback id="password-feedback">
                        {{ $t('macMenu.macDontMatch') }}
                    </b-form-invalid-feedback>
                </b-col>
            </b-row>
        </div>
        <div>
            <b-row class="my-5" align-h="between">
                <b-col class="justify-content-start align-items-start text-left">
                    <router-link class="btn sansSerif mr-auto" style="background-color: #000000; border-radius: 0!important;
         color: white" :to="{ name: 'home'}">
                        {{ $t('macMenu.backToBoxSelection') }}
                    </router-link>
                </b-col>
                <b-col class="justify-content-end align-items-end text-end">
                    <b-button @click="next" class="btn sansSerif ml-auto" style="background-color: #0000FF; border-radius: 0!important;
         color: white">
                        {{ $t('next') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-modal id="modal-mac" centered title="Info" :ok-title="$t('macMenu.generateKey')" @ok="generateKey"
                 :cancel-title="$t('cancel')">
            <b-row style="margin-bottom: 5px">
                <b-col sm="12">
                    <p>
                        {{ $t('macMenu.pvrAlreadyPurchased') }}
                    </p>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import TransactionDataService from "../services/TransactionDataService";

export default {
    name: "Q90",
    data() {
        return {
            macAddress: '',
            macAddressConfirm: '',
            name: this.boxName,
            transaction: {
                id: null,
                userId: null,
                boxTypeId: this.$route.query.id,
                macAddress: '',
                pvrNumber: '',
            },
            submitted: false,
            error: false,
            errorMsg: 'The entered mac address is not correct, please check the entry again',
            items: [
                {
                    text: 'Home',
                    href: '#/home'
                },
                {
                    text: 'Q90',
                    active: true
                },
            ]
        }
    },
    computed: {
        macAddressState() {
            let testString = this.macAddress.replace(/[^:]/g, "").length;
            const regex = "[a-fA-F0-9][a-fA-F0-9][:-][a-fA-F0-9][a-fA-F0-9][:-][a-fA-F0-9][a-fA-F0-9][:-][a-fA-F0-9][a-fA-F0-9][:-][a-fA-F0-9][a-fA-F0-9][:-][a-fA-F0-9][a-fA-F0-9]"
            let reg = new RegExp(regex);
            console.log(reg);
            const matches = reg.test(this.macAddress);
            console.log(matches);
            if (this.macAddress === "") {
                if (this.macAddress === '' && this.submitted) {
                    return false;
                }
                return null;
            }
            if (this.macAddress.length < 17 || this.macAddress.length > 17 || testString.length < 5 || this.macAddress === '' && this.submitted || !matches) {
                return false;
            } else {
                return true;
            }
        },
        macAddressConfirmState() {
            if (this.macAddress === '' || this.macAddressConfirm === '') {
                return null;
            } else if (this.macAddress !== this.macAddressConfirm) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        saveTransaction() {
            let testString = this.macAddress.replace(/[^:]/g, "").length;
            console.log(testString);
            if (this.macAddress.length < 17 || this.macAddress.length > 17 || testString.length < 5) {
                this.error = true;
            } else {
                this.error = false;
                let data = {
                    userId: 14,
                    boxTypeId: this.$route.query.id,
                    macAddress: this.macAddress,
                };
                console.log(this.macAddress);
                console.log(data);
            }
        },

        generateKey() {
            console.log("KEY");
            let data = {
                macAddress: this.macAddress,
            }
            TransactionDataService.generateKey(data).then(response => {
                console.log(response.data);
                this.$store.commit("SET_PVR_NUMBER", {pvrNumber: response.data});
                this.$store.commit("SET_PURCHASED", {purchased: false});
                this.$router.push({
                    name: 'pvrTicket',
                    query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.macAddress}
                });
            }).catch(e => {
                console.log(e);
            });
        },

        next() {
            if (this.macAddressState && this.macAddressConfirmState) {
                this.$store.commit('SET_MAC_ADDRESS', {macAddress: this.macAddress});
                this.$store.commit('SET_BOX_TYPE', {boxType: this.$route.query.id});
                let accessToken = this.$store.getters.accessToken;
                let data = {
                    macAddress: this.macAddress,
                }
                TransactionDataService.checkMac(data).then(response => {
                    console.log("RESPONSE");
                    console.log(response);
                    if (response.data) {
                        this.$bvModal.show("modal-mac");
                    } else {
                        if (accessToken) {
                            this.$router.push({
                                name: 'Overview',
                                query: {
                                    id: this.$route.query.id,
                                    boxName: this.$route.query.boxName,
                                    mac: this.macAddress
                                }
                            });
                        } else {
                            this.$router.push({
                                name: 'orderOptions',
                                query: {
                                    id: this.$route.query.id,
                                    boxName: this.$route.query.boxName,
                                    mac: this.macAddress
                                }
                            });
                        }
                    }
                }).catch(e => {
                    console.log(e);
                });

            } else {
                this.submitted = true;
            }
        },
    },
    mounted() {
        this.macAddress = this.$route.query.macAddress;
        this.macAddressConfirm = this.$route.query.macAddress;
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');

.breadcrumb-item a {
    color: #0000FF;
}

.ubuntu {
    font-family: 'Ubuntu', sans-serif !important;
}

.sansSerif {
    font-family: 'Open Sans', sans-serif !important;
}
</style>
